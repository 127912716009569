'use strict';
var base = require('./base');
var focusHelper = require('base/components/focus');
window.jqueryzoom = window.jqueryZoom = require('jquery-zoom/jquery.zoom.min');
var imagesLoaded = require('imagesloaded');
var customerservice = require('../pages/customerservice');
var addOffer = require('mirakl/product/addOffer').addOfferToCart;
var miraklQuantityForm = require('mirakl/product/miraklQuantityForm').offerAvailability;
var loadMiraklOffers = require('mirakl/product/loadMiraklOffers');
var powerReview = require('../product/powerReview');

/**
 * Update Buy Box with the offer information
 */
function updateBuyBox() {
    var useBuyBox = $('.miraklQuickViewBuyBox');

    if (useBuyBox.length !== 0) {
        var url = $('.miraklQuickViewBuyBox').data('url');
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $('.buybox')
                    .empty()
                    .html(data);
                if (data.indexOf('offer-add-to-cart') !== -1) {
                    $('.product-option').addClass('d-none');
                    $('.promotions').addClass('d-none');
                }
            }
        });
    }
}

/**
 * Updates the buybox and load offers when a variant is changed
 * @param {Object} data - The response data from the ajax call(Product-Variant)
 */
function updateVariationBuyBox(data) {
    if (data && data.isMiraklEnabled) {
        $('.buybox')
            .empty()
            .html(data.quickViewBuyboxHtml);
        if (data.callP11) {
            if (data.useOffer) {
                $('.attribute.quantity').addClass('d-none');
            } else {
                $('.attribute.quantity').removeClass('d-none');
            }
            var localeParts = data.locale.split('_');
            var selectedCountry = localeParts.length > 1 ? localeParts[1].toUpperCase() : null;
            if (selectedCountry != null && selectedCountry == "US") {
                loadMiraklOffers.loadMiraklOffers();
            }
        } else {
            $('.attribute.quantity').removeClass('d-none');
            $('.mirakl-offer-list').addClass('d-none');
            $('.mirakl-offer-list').empty();
        }

        if (data.quickViewBuyboxHtml.indexOf('offer-add-to-cart') !== -1) {
            $('.promotions').addClass('d-none');
        } else {
            $('.promotions').removeClass('d-none');
        }
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="quickViewModal" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <a class="full-pdp-link d-none" href=""></a>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span class="close-icon"></span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(selectedValueUrl) {
    // $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#quickViewModal .modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-footer').html(parsedHtml.footer);
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            $('#quickViewModal').modal('show');
            $('.simple-quantity').addClass('d-none');
            $('body').trigger('quickview:ready');
            updateBuyBox();
            loadZoom();
            const $quickViewModal = $('#quickViewModal');
            const $focusableElements = $quickViewModal.find('input:visible, button:visible, a:visible, select:visible, [tabindex="0"]');
            let currentIndex = 0;
            $quickViewModal.on('keydown', function(e) {
                if (e.key === 'Tab' || e.keyCode === 9) {
                    e.preventDefault();
                    currentIndex = (currentIndex + 1) % $focusableElements.length;
                    $focusableElements[currentIndex].focus();
                }
            });
            $('.size-chart-modal .modal-body').html('');

            $('[data-toggle="popover"]').popover();

            // $.spinner().stop();
        },
        error: function () {
            // $.spinner().stop();
        },
        complete: function(data) {
            // It takes time for amplience to generate the images, so I add a short delay to execute the code.
            setTimeout(function() {
                var zoomImages = document.querySelectorAll('img[role="presentation"].zoomImg');
                var regularImages = document.querySelectorAll('img.carousel-image');

                zoomImages.forEach(function(zoomImage, index) {
                    zoomImage.alt = regularImages[index].alt + ' - image with zoom';
                });
                powerReview.checkPowerReviewAvailability();
            }, 500);
        }
    });
}

/**
* PDP loadZoom Event
*/
function loadZoom() {
    var imgZoom = '.primary-images .desktop-zoom';
    var container = imgZoom;
    imagesLoaded(container).on('always', function () {
        var $container = $(container);
        $(container).trigger('zoom.destroy');
        $(container).find('.zoomImg').remove();
        $container.zoom();
    });
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            fillModalElement(selectedValueUrl);
        });
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#quickViewModal', function () {
            $('#quickViewModal .close').focus();
            $('#cart-modal').modal('hide');
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#quickViewModal', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#quickViewModal',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-input'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: function () {
        // $('body').on('product:beforeAddToCart', function (e, data) {
        //    $(data).closest('.modal-content').spinner().start();
        // });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            // $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link')
                    .attr('href', response.data.product.selectedProductUrl);
                    if (response.data && response.data.product && response.data.product.isMarketplaceProduct) {
                        updateVariationBuyBox(response.data);
                    }
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            var hasErrors = $('.quick-view-dialog .select-styles, .quantity-error.d-block', '.third-party-not-available').length > 0;

            var shouldDisable = !(response.product && response.product.readyToOrder)|| !(response.product && response.product.available) || hasErrors;
            $('button.add-to-cart, button.add-to-cart-global', response.$productContainer).attr('disabled', shouldDisable);

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.add-to-cart-global', dialog).attr('disabled', shouldDisable);
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html();


            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    },
    sizeChart: function () {
        $(document).on('click', '.size-chart button', function (e) {
            e.preventDefault();
            $(this).closest('.modal').find('.close').trigger('click');
            var url = $(this).attr('href');
            var $prodSizeChart = $('.size-chart-modal .modal-body');
            if ($prodSizeChart.is(':empty')) {
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'html',
                    success: function (response) {
                        $prodSizeChart.html(JSON.parse(response).content);
                        $('#sizechart-modal').modal('show');
                        customerservice.init();
                    },
                    error: function () {
                        $('#sizechart-modal').remove();
                    }
                });
            } else {
                $('#sizechart-modal').modal('show');
                customerservice.init();
            }
        });

        var $sizeChart = $('.size-chart-collapsible');
        $('body').on('click touchstart', function (e) {
            if ($('.size-chart').has(e.target).length <= 0) {
                $sizeChart.removeClass('active');
            }
        });
    },
    miraklQuantityForm : miraklQuantityForm,
    addOffer : addOffer
};
